import leaflet from 'leaflet';
import 'leaflet/dist/leaflet.css';

import {isEmpty, eventIcon} from '../modules/tools';

let map = null;
let pathLayer = null;
let markersLayer = null;
let trackersLayer = null;
let trackersMarkers = [];

const iconSize = 30;

export default (template) => {
	return {
		props: {
			stageEvents: Object,
			currentStage: Object,
		},
		template,
		watch: {
			'stageEvents'() {
				this.drawMap();
			},
			'currentStage'() {
				this.drawMap();
			},
			'$live.count'() {
				this.drawTrackers();
			},
			'$live.centeredMarkerId'(id) {
				this.centerOnMarker(id);
			},
		},
		data() {
			return {
				loading: false,
			};
		},
		mounted() {
			this.drawMap();
		},
		methods: {
			drawMap() {
				//console.log("draw map!");
				this.loading = true;

				const pointsCoords = this.currentStage.roads.map(e => [e.lt, e.lg]);

				if (map !== null) {
					map.eachLayer((layer) => {
						layer.remove();
					});

					pathLayer = null;
					markersLayer = null;
					trackersLayer = null;
					trackersMarkers = [];

					map.remove();
					map = null;
				}

				map = leaflet.map('map');
				map.setView([51.505, -0.09], 2);
				leaflet.tileLayer('https://tile.openstreetmap.org/{z}/{x}/{y}.png', {
					maxZoom: 19,
					attribution: '&copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>'
				}).addTo(map);

				//////////// create path
				pathLayer = leaflet.layerGroup();

				let latlngs = [];
				for (let pointsCoord of pointsCoords) {
					latlngs.push(pointsCoord);
				}
				const polylines = leaflet.polyline(latlngs, {color: 'red'});
				pathLayer.addLayer(polylines);
				pathLayer.addTo(map);

				// zoom the map to the polyline
				map.fitBounds(polylines.getBounds());

				///////////// add icons
//				if (markersLayer !== null) {
//					markersLayer.removeFrom(map);
//				}
				markersLayer = leaflet.layerGroup();

				for (let event of this.stageEvents) {
					let point = this.currentStage.roads.find(e => e.startKilo >= event.km);
					const iconUrl = eventIcon(event.category, event.code);

					if (['END'].includes(event.code) === true) {
						point = this.currentStage.roads[this.currentStage.roads.length - 1];
					}
					if (['START'].includes(event.code) === true) {
						point = this.currentStage.roads[0];
					}

					if (isEmpty(point) === false) {
						const icon = leaflet.icon({
							iconUrl,
							iconSize: [iconSize / 2, iconSize / 2],
							iconAnchor: [iconSize / 4, iconSize / 4],
							popupAnchor: [0, iconSize / 4 * -1],
						});
						const marker = leaflet.marker([point.lt, point.lg], {
							icon,
							title: event.name,
						});
						//marker.bindTooltip(this.getEventHTML(event), {permanent: true}).openTooltip();
						markersLayer.addLayer(marker);
					}
				}

				markersLayer.addTo(map);

				this.drawTrackers();

				setTimeout(() => {
					this.loading = false;
				}, 500);
			},
			drawTrackers() {
				//console.log('drawTrackers!');

				if (map === null) {
					return;
				}

				if (trackersLayer === null) {
					trackersLayer = leaflet.layerGroup();
					trackersLayer.addTo(map);
				}

				Object.values(trackersMarkers).map((t) => {
					t.mustBeDelete = true;
				});

				/*this.$live.trackerPositions.push(
						{
							id: 'MOBILE05_2024_RTC',
							name: 'MOBILE05_2024_RTC',
							lat: 50.464033333333326,
							lon: 3.02977,
							foreground: 'white',
							background: 'red',
							nameShort: 'icone',
						});*/

				for (let tracker of this.$live.trackerPositions) {
					const newPoint = {lt: parseFloat(tracker.lat), lg: parseFloat(tracker.lon)};
					if (typeof trackersMarkers[tracker.id] === 'undefined') {
						const icon = leaflet.divIcon();
						const marker = leaflet.marker([newPoint.lt, newPoint.lg], {
							icon,
							title: tracker.name,
						});
						marker.bindTooltip(this.getTrackerHTML(tracker), {
							permanent: true,
							className: 'bg-' + (tracker.background ? tracker.background.replace('#', '') : 'black'),
						}).openTooltip();
						trackersLayer.addLayer(marker);

						// store marker
						trackersMarkers[tracker.id] = marker;
					} else {
						trackersMarkers[tracker.id].setLatLng(new leaflet.LatLng(newPoint.lt, newPoint.lg)).update();
						trackersMarkers[tracker.id]._tooltip._container.innerHTML = this.getTrackerHTML(tracker);
					}
					trackersMarkers[tracker.id].mustBeDelete = false;
				}

				Object.values(trackersMarkers).map((t) => {
					if (t.mustBeDelete === true) {
						map.removeLayer(t);
						delete trackersMarkers[t.id];
					}
				});
			},
			//getEventHTML(event) {
			//	return `<div style="padding: 4px 6px;">${event.name}</div>`;
			//},
			fixDist(dist) {
				if (dist > 500) {
					return dist / 1000;
				}
				return dist;
			},
			getTrackerHTML(tracker) {
				const distHTML = isEmpty(tracker.dist) === false ? Math.round(this.fixDist(tracker.dist)) + 'km' : '';
				return `<div style="padding: 4px 6px; color: ${tracker.foreground ? tracker.foreground : 'white'}; background-color: ${tracker.background ? tracker.background : 'black'}">${tracker.nameShort} ${distHTML}</div>`;
			},
			centerOnMarker(id) {
				const marker = trackersMarkers[id];
				if (isEmpty(marker) === true) {
					console.log('invalid marker', id);
					return;
				}
				const latLngs = [marker.getLatLng()];
				const markerBounds = leaflet.latLngBounds(latLngs);
				map.fitBounds(markerBounds);
			},
		},
	};
};
